import { toast } from "react-toastify";
import {
  PRESCRIBER_LIST_REQUEST,
  PRESCRIBER_LIST_SUCCESS,
  PRESCRIBER_LIST_FAIL,
  //
  PRESCRIBER_ADD_REQUEST,
  PRESCRIBER_ADD_SUCCESS,
  PRESCRIBER_ADD_FAIL,
  //
  PRESCRIBER_DETAIL_REQUEST,
  PRESCRIBER_DETAIL_SUCCESS,
  PRESCRIBER_DETAIL_FAIL,
  //
  PRESCRIBER_UPDATE_REQUEST,
  PRESCRIBER_UPDATE_SUCCESS,
  PRESCRIBER_UPDATE_FAIL,
  //
  PRESCRIBER_DELETE_REQUEST,
  PRESCRIBER_DELETE_SUCCESS,
  PRESCRIBER_DELETE_FAIL,
  //
  PRESCRIBER_RESERVATION_REQUEST,
  PRESCRIBER_RESERVATION_SUCCESS,
  PRESCRIBER_RESERVATION_FAIL,
  //
} from "../constants/prescriberConstants";

export const getPrescriberListReservationsReducer = (
  state = { reservationPrescribers: [], totalPayment: 0 },
  action
) => {
  switch (action.type) {
    case PRESCRIBER_RESERVATION_REQUEST:
      return {
        loadingPrescriberReservation: true,
        reservationPrescribers: [],
        totalPayment: 0,
      };
    case PRESCRIBER_RESERVATION_SUCCESS:
      return {
        loadingPrescriberReservation: false,
        reservationPrescribers: action.payload.reservations,
        totalPayment: action.payload.total_payment,
        successPrescriberReservation: true,
      };
    case PRESCRIBER_RESERVATION_FAIL:
      return {
        loadingPrescriberReservation: false,
        errorPrescriberReservation: action.payload,
        successPrescriberReservation: false,
      };
    default:
      return state;
  }
};

export const deletePrescriberReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_DELETE_REQUEST:
      return { loadingPrescriberDelete: true };
    case PRESCRIBER_DELETE_SUCCESS:
      toast.success("Ce Prescripteur a été supprimer avec succès");
      return {
        loadingPrescriberDelete: false,
        successPrescriberDelete: true,
      };
    case PRESCRIBER_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingPrescriberDelete: false,
        successPrescriberDelete: false,
        errorPrescriberDelete: action.payload,
      };
    default:
      return state;
  }
};

export const updatePrescriberReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_UPDATE_REQUEST:
      return { loadingPrescriberUpdate: true };
    case PRESCRIBER_UPDATE_SUCCESS:
      toast.success("Ce Prescripteur a été mis à jour avec succès");
      return {
        loadingPrescriberUpdate: false,
        successPrescriberUpdate: true,
      };
    case PRESCRIBER_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingPrescriberUpdate: false,
        successPrescriberUpdate: false,
        errorPrescriberUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailPrescriberReducer = (state = { prescriber: {} }, action) => {
  switch (action.type) {
    case PRESCRIBER_DETAIL_REQUEST:
      return { loadingDetailPrescriber: true };
    case PRESCRIBER_DETAIL_SUCCESS:
      return {
        loadingDetailPrescriber: false,
        successDetailPrescriber: true,
        prescriber: action.payload,
      };
    case PRESCRIBER_DETAIL_FAIL:
      return {
        loadingDetailPrescriber: false,
        successDetailPrescriber: false,
        errorDetailPrescriber: action.payload,
      };
    default:
      return state;
  }
};

export const createPrescriberReducer = (state = {}, action) => {
  switch (action.type) {
    case PRESCRIBER_ADD_REQUEST:
      return { loadingPrescriberAdd: true };
    case PRESCRIBER_ADD_SUCCESS:
      toast.success("Ce Prescripteur a été ajouté avec succès");
      return {
        loadingPrescriberAdd: false,
        successPrescriberAdd: true,
      };
    case PRESCRIBER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingPrescriberAdd: false,
        successPrescriberAdd: false,
        errorPrescriberAdd: action.payload,
      };
    default:
      return state;
  }
};

export const getPrescriberListReducer = (
  state = { prescribers: [] },
  action
) => {
  switch (action.type) {
    case PRESCRIBER_LIST_REQUEST:
      return { loadingPrescriber: true, prescribers: [] };
    case PRESCRIBER_LIST_SUCCESS:
      return {
        loadingPrescriber: false,
        prescribers: action.payload.prescribers,
        successPrescriber: true,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRESCRIBER_LIST_FAIL:
      return {
        loadingPrescriber: false,
        errorPrescriber: action.payload,
        successPrescriber: false,
      };
    default:
      return state;
  }
};
