import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginScreen from "./screens/auth/LoginScreen";
import LogoutScreen from "./screens/auth/LogoutScreen.js";
import DashboardScreen from "./screens/dashboard/DashboardScreen.js";
import ClientScreen from "./screens/client/ClientScreen.js";
import AddClientScreen from "./screens/client/AddClientScreen.js";
import EditClientScreen from "./screens/client/EditClientScreen.js";

import UserScreen from "./screens/users/UserScreen.js";
import AddUserScreen from "./screens/users/AddUserScreen.js";
import EditUserScreen from "./screens/users/EditUserScreen.js";

import ReservationScreen from "./screens/reservation/ReservationScreen.js";
import AddReservationScreen from "./screens/reservation/AddReservationScreen.js";
import EditReservationScreen from "./screens/reservation/EditReservationScreen.js";

import ProfileScreen from "./screens/profile/ProfileScreen.js";
import PrescripteurScreen from "./screens/prescripteur/PrescripteurScreen.js";
import AddPrescripteurScreen from "./screens/prescripteur/AddPrescripteurScreen.js";
import EditPrescripteurScreen from "./screens/prescripteur/EditPrescripteurScreen.js";
import HistoryPrescripteurScreen from "./screens/prescripteur/HistoryPrescripteurScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  // client
  {
    path: "/clients",
    element: <ClientScreen />,
  },
  {
    path: "/clients/add",
    element: <AddClientScreen />,
  },
  {
    path: "/clients/edit/:id",
    element: <EditClientScreen />,
  },
  // prescripteur
  {
    path: "/prescripteurs",
    element: <PrescripteurScreen />,
  },
  {
    path: "/prescripteurs/add",
    element: <AddPrescripteurScreen />,
  },
  {
    path: "/prescripteurs/edit/:id",
    element: <EditPrescripteurScreen />,
  },
  {
    path: "/prescripteurs/history/:id",
    element: <HistoryPrescripteurScreen />,
  },
  // reservation
  {
    path: "/reservations",
    element: <ReservationScreen />,
  },
  {
    path: "/reservations/add",
    element: <AddReservationScreen />,
  },
  {
    path: "/reservations/edit/:id",
    element: <EditReservationScreen />,
  },

  // employes
  {
    path: "/users",
    element: <UserScreen />,
  },
  {
    path: "/users/add",
    element: <AddUserScreen />,
  },
  {
    path: "/users/edit/:id",
    element: <EditUserScreen />,
  },

  //
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
