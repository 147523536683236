import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import InputModel from "../../components/InputModel";

import ConfirmationModal from "../../components/ConfirmationModal";
import {
  deletePrescriber,
  getPrescriberList,
} from "../../redux/actions/prescriberActions";
import { baseURL } from "../../constants";

function PrescripteurScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [prescriberCode, setPrescriberCode] = useState("");
  const [prescriberName, setPrescriberName] = useState("");
  const [prescriberPhone, setPrescriberPhone] = useState("");
  const [prescriberEmail, setPrescriberEmail] = useState("");

  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [prescriberId, setPrescriberId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listPrescriber = useSelector((state) => state.getPrescriberList);
  const { prescribers, loadingPrescriber, errorPrescriber, pages } =
    listPrescriber;

  const prescriberDelete = useSelector((state) => state.deletePrescriber);
  const {
    loadingPrescriberDelete,
    errorPrescriberDelete,
    successPrescriberDelete,
  } = prescriberDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(
        getPrescriberList(
          page,
          prescriberCode,
          prescriberName,
          prescriberEmail,
          prescriberPhone
        )
      );
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    if (successPrescriberDelete) {
      dispatch(
        getPrescriberList(
          1,
          prescriberCode,
          prescriberName,
          prescriberEmail,
          prescriberPhone
        )
      );
    }
  }, [successPrescriberDelete]);

  //
  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Prescripteurs</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des prescripteurs
            </h4>
            <div className="flex flex-row justify-center items-center  my-1">
              <Link
                to={
                  baseURL +
                  `/prescribers/export-to-excel/?prescriber_code=${prescriberCode}&prescriber_name=${prescriberName}&prescriber_email=${prescriberEmail}&prescriber_phone=${prescriberPhone}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="rounded bg-danger text-white px-5 py-1 flex flex-row text-sm mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
                <div>Exporter</div>
              </Link>
              <Link
                to={"/prescripteurs/add"}
                className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <div>Ajouter</div>
              </Link>
            </div>
          </div>
          {/* search */}
          <div className=" flex md:flex-row flex-col w-full  ">
            <div className="md:w-1/2 w-full flex flex-row md:py-2  ">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Prescripteur N°"
                  value={prescriberCode}
                  onChange={(v) => {
                    setPrescriberCode(v.target.value);
                    dispatch(
                      getPrescriberList(
                        1,
                        v.target.value,
                        prescriberName,
                        prescriberEmail,
                        prescriberPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Nom de Prescripteur .."
                  value={prescriberName}
                  onChange={(v) => {
                    setPrescriberName(v.target.value);
                    dispatch(
                      getPrescriberList(
                        1,
                        prescriberCode,
                        v.target.value,
                        prescriberEmail,
                        prescriberPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
            </div>
            <div className="md:w-1/2 w-full flex flex-row md:py-2">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="email"
                  placeholder="Prescripteur Email"
                  value={prescriberEmail}
                  onChange={(v) => {
                    setPrescriberEmail(v.target.value);
                    dispatch(
                      getPrescriberList(
                        1,
                        prescriberCode,
                        prescriberName,
                        v.target.value,
                        prescriberPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="tele"
                  placeholder="Prescripteur phone"
                  value={prescriberPhone}
                  onChange={(v) => {
                    setPrescriberPhone(v.target.value);
                    dispatch(
                      getPrescriberList(
                        1,
                        prescriberCode,
                        prescriberName,
                        prescriberEmail,
                        v.target.value
                      )
                    );
                  }}
                  error={""}
                />
              </div>
            </div>
          </div>

          {/* list */}
          {loadingPrescriber ? (
            <Loader />
          ) : errorPrescriber ? (
            <Alert type="error" message={errorPrescriber} />
          ) : errorPrescriberDelete ? (
            <Alert type="error" message={errorPrescriberDelete} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      N°
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black  text-xs w-max">
                      VIP
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Nom
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Prénom
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Mobile
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Pay
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Ville
                    </th>

                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Statut
                    </th>
                    <th className="py-4 px-4 font-bold text-black  text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {prescribers?.map((prescriber, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4    ">
                        <Link to={"/prescripteurs/history/" + prescriber.id}>
                          <p className="text-primary   text-xs w-max">
                            {prescriber.id}
                          </p>
                        </Link>
                      </td>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4    ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.is_vip ? "OUI" : "NON"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.first_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.last_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.email ?? "---"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.phone ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.country ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.city ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {prescriber.is_active ? "Active" : "Deactive"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* list history */}
                          <Link
                            className="mx-1 history-class"
                            to={"/prescripteurs/history/" + prescriber.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-green rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                              />
                            </svg>
                          </Link>
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/prescripteurs/edit/" + prescriber.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setPrescriberId(prescriber.id);
                              setIsDelete(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/prescripteurs?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Êtes-vous sûr de vouloir supprimer ce prescripteur?"
              : "Êtes-vous sûr de vouloir ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && prescriberId !== "") {
              setLoadEvent(true);
              dispatch(deletePrescriber(prescriberId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default PrescripteurScreen;
