import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  detailPrescriber,
  getPrescriberListReservations,
} from "../../redux/actions/prescriberActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import InputModel from "../../components/InputModel";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function HistoryPrescripteurScreen() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");

  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const prescriberDetail = useSelector((state) => state.detailPrescriber);
  const { prescriber } = prescriberDetail;

  const listPrescriberReservation = useSelector(
    (state) => state.getPrescriberListReservations
  );
  const {
    reservationPrescribers,
    loadingPrescriberReservation,
    errorPrescriberReservation,
    totalPayment,
  } = listPrescriberReservation;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailPrescriber(id));
      dispatch(getPrescriberListReservations(id, startDate, endDate));
    }
  }, [navigate, userInfo, dispatch, id]);

  return (
    <DefaultLayout>
      <div>
        {/*  */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/prescripteurs">
            <div className="">Prescripteurs</div>
          </a>
          {prescriber && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
          )}
          {prescriber && (
            <div className="">
              {prescriber.first_name ?? ""} {prescriber.last_name ?? ""}
            </div>
          )}
        </div>
        {/* info  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des prescripteurs
            </h4>
          </div>
          {prescriber && prescriber != undefined && prescriber != null && (
            <div className="my-3 flex md:flex-row flex-col">
              <table className="md:w-1/2 w-full">
                <tr>
                  <td className="text-sm font-bold ">Prescripteur : </td>
                  <td className=" text-sm px-2">
                    {prescriber.first_name ?? ""} {prescriber.last_name ?? ""}
                  </td>
                </tr>
                <tr>
                  <td className="text-sm font-bold ">Email : </td>
                  <td className=" text-sm px-2">{prescriber.email ?? ""}</td>
                </tr>
                <tr>
                  <td className="text-sm font-bold ">Phone : </td>
                  <td className=" text-sm px-2">{prescriber.phone ?? ""}</td>
                </tr>
              </table>
              {/*  */}
              <table className="md:w-1/2 w-full">
                {/* <tr>
                  <td className="text-sm font-bold ">Commission : </td>
                  <td className=" text-sm px-2">
                    {prescriber.type_commission === "Variables"
                      ? prescriber.commission + "%" ?? "0%"
                      : parseFloat(prescriber.commission).toFixed(2)}{" "}
                    ({prescriber.type_commission})
                  </td>
                </tr> */}
                <tr>
                  <td className="text-sm font-bold ">Total : </td>
                  <td className=" text-sm px-2 ">
                    {totalPayment
                      ? parseFloat(totalPayment).toFixed(2)
                      : "0.00"}
                  </td>
                </tr>
              </table>
            </div>
          )}
          <div className="my-5 mx-5 flex md:flex-row flex-col">
            <InputModel
              label="Date de début"
              type="date"
              placeholder=""
              value={startDate}
              onChange={(v) => {
                setStartDate(v.target.value);
                dispatch(
                  getPrescriberListReservations(id, v.target.value, endDate)
                );
              }}
              error={setStartDateError}
            />
            <InputModel
              label="Date de fin"
              type="date"
              placeholder=""
              value={endDate}
              onChange={(v) => {
                setEndDate(v.target.value);
                dispatch(
                  getPrescriberListReservations(id, startDate, v.target.value)
                );
              }}
              error={endDateError}
            />
          </div>
          <div>
            {loadingPrescriberReservation ? (
              <Loader />
            ) : errorPrescriberReservation ? (
              <Alert type="error" message={errorPrescriberReservation} />
            ) : (
              <div className="max-w-full overflow-x-auto mt-3">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2 text-left ">
                      <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                        NR
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Date
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Heure
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Client
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        N-Personnes
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Tables
                      </th>

                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Statut
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Total
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                        Commission
                      </th>
                      <th className="py-4 px-4 font-bold text-black text-xs w-max">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {/*  */}
                  <tbody>
                    {reservationPrescribers?.map((reservation, index) => (
                      <tr key={index}>
                        <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                          <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                            <div>{reservation.id}</div>
                            {reservation.is_new && (
                              <div className="mx-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-3 text-danger cursor-pointer newreser-class"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                  />
                                </svg>
                              </div>
                            )}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_date ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_time ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.client?.first_name ?? "---"}{" "}
                            {reservation.client?.last_name ?? "---"}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.nbr_pax ?? ""}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.reservation_table ?? ""} -{" "}
                            {reservation.table_type ?? ""}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.status ?? ""}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {parseFloat(reservation.total_payment).toFixed(2) ??
                              "0.00"}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max  ">
                            {reservation.commission_type === 1
                              ? (
                                  (parseFloat(reservation.total_payment) *
                                    reservation.commission) /
                                  100
                                ).toFixed(2)
                              : parseFloat(reservation.commission).toFixed(2)}
                          </p>
                        </td>

                        <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                          <p className="text-black  text-xs w-max flex flex-row  ">
                            {/* delete */}

                            <Link
                              className="mx-1 update-class"
                              to={"/reservations/edit/" + reservation.id}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                            </Link>
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr className="h-11"></tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="grid md:grid-cols-2 w-full container mt-5"></div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default HistoryPrescripteurScreen;
