import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clientList, deleteClient } from "../../redux/actions/clientActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import InputModel from "../../components/InputModel";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import ConfirmationModal from "../../components/ConfirmationModal";
import { baseURL } from "../../constants";

function ClientScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();
  // const [code, setCode] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [gsmPhone, setGsmPhone] = useState("");
  // const [cinNumber, setCinNunber] = useState("");
  // const [permiNumber, setPermiNumber] = useState("");
  // const [orderBy, setOrderBy] = useState("-created_at");

  const [isUpdate, setIsUpdate] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [clientId, setClientId] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listClient = useSelector((state) => state.clientList);
  const { clients, loading, error, pages } = listClient;

  const clientDelete = useSelector((state) => state.deleteClient);
  const { loadingClientDelete, errorClientDelete, successClientDelete } =
    clientDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(clientList(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    if (successClientDelete) {
      dispatch(
        clientList(page, clientCode, clientName, clientEmail, clientPhone)
      );
    }
  }, [successClientDelete]);

  const [clientCode, setClientCode] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Clients</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default   dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black  text-xs w-max">
              Gestion des clients
            </h4>
            <div className="flex flex-row items-center justify-center my-1">
              <Link
                to={
                  baseURL +
                  `/clients/export-to-excel/?client_code=${clientCode}&client_name=${clientName}&client_email=${clientEmail}&client_phone=${clientPhone}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="rounded bg-danger text-white px-5 py-1 flex flex-row text-sm mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
                <div>Exporter</div>
              </Link>
              <Link
                to={"/clients/add"}
                className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5  mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <div>Ajouter</div>
              </Link>
            </div>
          </div>
          {/* search */}
          <div className=" flex md:flex-row flex-col w-full  ">
            <div className="md:w-1/2 w-full flex flex-row md:py-2  ">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Client N°"
                  value={clientCode}
                  onChange={(v) => {
                    setClientCode(v.target.value);
                    dispatch(
                      clientList(
                        1,
                        v.target.value,
                        clientName,
                        clientEmail,
                        clientPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Nom du client"
                  value={clientName}
                  onChange={(v) => {
                    setClientName(v.target.value);
                    dispatch(
                      clientList(
                        1,
                        clientCode,
                        v.target.value,
                        clientEmail,
                        clientPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
            </div>
            <div className="md:w-1/2 w-full flex flex-row md:py-2">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="email"
                  placeholder="E-mail du client"
                  value={clientEmail}
                  onChange={(v) => {
                    setClientEmail(v.target.value);
                    dispatch(
                      clientList(
                        1,
                        clientCode,
                        clientName,
                        v.target.value,
                        clientPhone
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="tele"
                  placeholder="Téléphone du client"
                  value={clientPhone}
                  onChange={(v) => {
                    setClientPhone(v.target.value);
                    dispatch(
                      clientList(
                        1,
                        clientCode,
                        clientName,
                        clientEmail,
                        v.target.value
                      )
                    );
                  }}
                  error={""}
                />
              </div>
            </div>
          </div>

          {/* list */}
          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black  text-xs w-max">
                      N°
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Type
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Nom
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Prénom
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Email
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Né le
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Mobile
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Téléphone
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Langue
                    </th>
                    {/* <th className="min-w-[120px] py-4 px-4 font-bold text-black  text-xs w-max">
                      Statut
                    </th> */}
                    <th className="py-4 px-4 font-bold text-black  text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {clients?.map((client, id) => (
                    <tr>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4    ">
                        <p className="text-black  text-xs w-max">{client.id}</p>
                      </td>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4    ">
                        <p className="text-black  text-xs w-max">
                          {client.type ?? ""}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.first_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.last_name ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.email ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.date_birth ?? "---"}
                        </p>
                      </td>

                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.phone ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.other_phone ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max">
                          {client.langue ?? "---"}
                        </p>
                      </td>
                      <td className="min-w-[120px] border-b border-[#eee] py-2 px-4  ">
                        <p className="text-black  text-xs w-max flex flex-row">
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/clients/edit/" + client.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setClientId(client.id);
                              setIsUpdate(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
              <div className="">
                <Paginate
                  route={"/clients?"}
                  search={""}
                  page={page}
                  pages={pages}
                />
              </div>
            </div>
          )}
        </div>
        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isUpdate}
          message={
            eventType === "delete"
              ? "Êtes-vous sûr de vouloir supprimer ce client ?"
              : "Êtes-vous sûr de vouloir ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && clientId !== "") {
              setLoadEvent(true);
              dispatch(deleteClient(clientId));
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsUpdate(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsUpdate(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
      <Tooltip anchorSelect=".paiement-class" place="top">
        <button className="text-xs font-bold">Paiement</button>
      </Tooltip>
      <Tooltip anchorSelect=".imprimer-class" place="top">
        <button className="text-xs font-bold">Imprimer</button>
      </Tooltip>
      <Tooltip anchorSelect=".update-class" place="top">
        <button className="text-xs font-bold">Modifier</button>
      </Tooltip>
    </DefaultLayout>
  );
}

export default ClientScreen;
