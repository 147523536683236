import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import Paginate from "../../components/Paginate";
import {
  deleteReservation,
  getListReservations,
} from "../../redux/actions/reservationActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import InputModel from "../../components/InputModel";
import { baseURL } from "../../constants";

function ReservationScreen() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [reservationCode, setReservationCode] = useState("");
  const [clientName, setClientName] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listReservation = useSelector((state) => state.reservationList);
  const { reservations, loading, error, pages } = listReservation;

  const reservationDelete = useSelector((state) => state.deleteReservation);
  const { successReservationDelete } = reservationDelete;

  //
  const [reservationId, setReservationId] = useState("");
  const [eventType, setEventType] = useState("");
  const [loadEvent, setLoadEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  //

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(
        getListReservations(
          page,
          reservationCode,
          clientName,
          reservationDate,
          reservationStatus
        )
      );
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    page,
    reservationCode,
    clientName,
    reservationDate,
    reservationStatus,
  ]);

  useEffect(() => {
    if (successReservationDelete) {
      dispatch(
        getListReservations(
          1,
          reservationCode,
          clientName,
          reservationDate,
          reservationStatus
        )
      );
    }
  }, [
    successReservationDelete,
    dispatch,
    reservationCode,
    clientName,
    reservationDate,
    reservationStatus,
  ]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Réservation</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black ">
              Gestion des Réservation
            </h4>
            <div className="flex flex-row justify-center items-center  my-1">
              <Link
                to={
                  baseURL +
                  `/reservations/export-to-excel/?reservation_code=${reservationCode}&client_name=${clientName}&reservation_date=${reservationDate}&reservation_status=${reservationStatus}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className="rounded bg-danger text-white px-5 py-1 flex flex-row text-sm mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
                <div>Exporter</div>
              </Link>
              <Link
                to={"/reservations/add"}
                className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 mx-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <div>Ajouter</div>
              </Link>
            </div>
          </div>
          <div className=" flex md:flex-row flex-col w-full  ">
            <div className="md:w-1/2 w-full flex flex-row md:py-2  ">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Réservation Code"
                  value={reservationCode}
                  onChange={(v) => {
                    setReservationCode(v.target.value);
                    dispatch(
                      getListReservations(
                        1,
                        v.target.value,
                        clientName,
                        reservationDate,
                        reservationStatus
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="text"
                  placeholder="Nom de Client .."
                  value={clientName}
                  onChange={(v) => {
                    setClientName(v.target.value);
                    dispatch(
                      getListReservations(
                        1,
                        reservationCode,
                        v.target.value,
                        reservationDate,
                        reservationStatus
                      )
                    );
                  }}
                  error={""}
                />
              </div>
            </div>
            <div className="md:w-1/2 w-full flex flex-row md:py-2">
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="date"
                  placeholder="Réservation Date"
                  value={reservationDate}
                  onChange={(v) => {
                    setReservationDate(v.target.value);
                    dispatch(
                      getListReservations(
                        1,
                        reservationCode,
                        clientName,
                        v.target.value,
                        reservationStatus
                      )
                    );
                  }}
                  error={""}
                />
              </div>
              <div className="w-full mx-1">
                <InputModel
                  label=""
                  type="select"
                  placeholder="Réservation Status"
                  value={reservationStatus}
                  onChange={(v) => {
                    setReservationStatus(v.target.value);
                    dispatch(
                      getListReservations(
                        1,
                        reservationCode,
                        clientName,
                        reservationDate,
                        v.target.value
                      )
                    );
                  }}
                  error={""}
                  options={[
                    {
                      value: "RESERVED",
                      label: "RESERVED",
                    },
                    {
                      value: "CHECK",
                      label: "CHECK",
                    },
                    {
                      value: "NO SHOW",
                      label: "NO SHOW",
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {/* list */}
          {loading ? (
            <Loader />
          ) : error ? (
            <Alert type="error" message={error} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      NR
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Date
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Heure
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Client
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      N-Personnes
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Tables
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Prescripteur
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Statut
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Chiffre d’affaires
                    </th>
                    <th className="py-4 px-4 font-bold text-black text-xs w-max">
                      Actions
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {reservations?.map((reservation, index) => (
                    <tr key={index}>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                          <div>{reservation.id}</div>
                          {reservation.is_new && (
                            <div className="mx-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-3 text-danger cursor-pointer newreser-class"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                />
                              </svg>
                            </div>
                          )}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.reservation_date ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.reservation_time ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.client?.first_name ?? "---"}{" "}
                          {reservation.client?.last_name ?? "---"}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.nbr_pax ?? ""}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.reservation_table ?? ""} -{" "}
                          {reservation.table_type ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.prescriber?.first_name ?? "---"}{" "}
                          {reservation.prescriber?.last_name ?? "---"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {reservation.status ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {parseFloat(reservation.total_payment).toFixed(2) ??
                            ""}
                        </p>
                      </td>

                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max flex flex-row  ">
                          {/* delete */}
                          <div
                            onClick={() => {
                              setEventType("delete");
                              setLoadEvent(false);
                              setReservationId(reservation.id);
                              setIsOpen(true);
                            }}
                            className="mx-1 delete-class cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                              />
                            </svg>
                          </div>
                          {/* edit */}
                          <Link
                            className="mx-1 update-class"
                            to={"/reservations/edit/" + reservation.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="">
            <Paginate
              route={"/reservations?"}
              search={""}
              page={page}
              pages={pages}
            />
          </div>
        </div>
        {/* buttom dash */}
        <ConfirmationModal
          isOpen={isOpen}
          message={
            eventType === "delete"
              ? "Êtes-vous sûr de vouloir supprimer cette réservation?"
              : "Êtes-vous sûr de vouloir confirmer cette operation ?"
          }
          onConfirm={async () => {
            if (eventType === "delete" && reservationId !== "") {
              setLoadEvent(true);
              await dispatch(deleteReservation(reservationId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            } else {
              setLoadEvent(false);
              setEventType("");
              setIsOpen(false);
            }
          }}
          onCancel={() => {
            setIsOpen(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default ReservationScreen;
