import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import LayoutSection from "../../components/LayoutSection";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { addNewUser } from "../../redux/actions/userActions";
import InputModel from "../../components/InputModel";

function AddUserScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [role, setRole] = useState("");
  const [errorRole, setErrorRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const [isAddUser, setIsAddUser] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventMode, setEventMode] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userAdd = useSelector((state) => state.createNewUser);
  const { successUserAdd } = userAdd;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (successUserAdd) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setPassword("");
      setRole("");
      setConfirmPassword("");
      setIsAddUser(false);
      setEventMode("");
      setLoadEvent(false);
    }
  }, [successUserAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Accueil</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/settings/users">
            <div className="">Liste des utilisateurs</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau utilisateur</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Ajouter un nouveau utilisateur
            </h4>
          </div>
          {/*  */}
          <LayoutSection title="Informations personnelles">
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Role*"
                type="select"
                placeholder=""
                value={role}
                onChange={(v) => setRole(v.target.value)}
                error={errorRole}
                options={[
                  {
                    value: 2,
                    label: "ADMIN",
                  },
                  {
                    value: 3,
                    label: "UTILISATEUR",
                  },
                ]}
              />
            </div>

            {/* fisrt name & last name */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Nom*"
                type="text"
                placeholder=""
                value={firstName}
                onChange={(v) => setFirstName(v.target.value)}
                error={errorFirstName}
              />
              <InputModel
                label="Prénom*"
                type="text"
                placeholder=""
                value={lastName}
                onChange={(v) => setLastName(v.target.value)}
                error={errorLastName}
              />
            </div>
            {/* phone and mail */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Email*"
                type="text"
                placeholder=""
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                error={errorEmail}
              />
              <InputModel
                label="Téléphone*"
                type="text"
                placeholder=""
                value={phone}
                onChange={(v) => setPhone(v.target.value)}
                error={errorPhone}
              />
            </div>

            {/* password */}
            <div className="md:py-2 md:flex ">
              <InputModel
                label="Mot de passe*"
                type="password"
                placeholder=""
                value={password}
                onChange={(v) => setPassword(v.target.value)}
                error={errorPassword}
              />
              <InputModel
                label="Confirm Mot de passe*"
                type="password"
                placeholder=""
                value={confirmPassword}
                onChange={(v) => setConfirmPassword(v.target.value)}
                error={errorConfirmPassword}
              />
            </div>
          </LayoutSection>

          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventMode("cancel");
                setIsAddUser(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Annuler
            </button>
            <button
              onClick={async () => {
                var check = true;

                setErrorRole("");
                setErrorFirstName("");
                setErrorLastName("");
                setErrorPhone("");
                setErrorEmail("");
                setErrorPassword("");
                setErrorConfirmPassword("");
                if (role === "") {
                  setErrorRole("Ce champ est requis.");
                  check = false;
                }
                if (firstName === "") {
                  setErrorFirstName("Ce champ est requis.");
                  check = false;
                }
                if (lastName === "") {
                  setErrorLastName("Ce champ est requis.");
                  check = false;
                }
                if (phone === "") {
                  setErrorPhone("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setErrorEmail("Ce champ est requis.");
                  check = false;
                }
                if (password === "") {
                  setErrorPassword("Ce champ est requis.");
                  check = false;
                }
                if (confirmPassword === "") {
                  setErrorConfirmPassword("Ce champ est requis.");
                  check = false;
                }

                if (password !== confirmPassword) {
                  setErrorPassword("Veuillez confirmer le mot de passe.");
                  check = false;
                }
                if (check) {
                  setEventMode("add");
                  setIsAddUser(true);
                } else {
                  toast.error(
                    "Certains champs sont obligatoires veuillez vérifier"
                  );
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Ajouter
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddUser}
          message={
            eventMode === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Êtes-vous sûr de vouloir ajouter cet utilisateur?"
          }
          onConfirm={async () => {
            if (eventMode === "cancel") {
              setRole("");
              setErrorRole("");
              setFirstName("");
              setErrorFirstName("");
              setLastName("");
              setErrorLastName("");
              setPhone("");
              setErrorPhone("");
              setEmail("");
              setErrorEmail("");
              setPassword("");
              setErrorPassword("");
              setConfirmPassword("");
              setErrorConfirmPassword("");
              setIsAddUser(false);
              setEventMode("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewUser({
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone: phone,
                  role: role,
                  password: password,
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventMode("");
            }
          }}
          onCancel={() => {
            setIsAddUser(false);
            setEventMode("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />

        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default AddUserScreen;
